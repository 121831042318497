<template>
    <div class="myExamDialog">
        <el-dialog :title="myTitle" width="70%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="学年学期" prop="semester">
                    <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称或入学年"  @keyup.enter.native='handleSearch'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='handleSearch'>查询</el-button>
                </el-form-item>
            </el-form>

            <el-table v-loading="loading" :data="tableData" :height="300" @selection-change="selectionChange" style="width: 100%" align='center'>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="semester" label="学年学期"  width="150" align='center' ></el-table-column>
                <el-table-column prop="name" label="试卷名称" align='center' :formatter="formatterExamName" ></el-table-column>
                <el-table-column prop="stu_count" label="试卷数"  width="80" align='center' ></el-table-column>
                <el-table-column prop="score" label="满分"  width="70" align='center' ></el-table-column>
            </el-table>
            <el-row>
                <el-col :span="6" class="head2">
                    成绩分档：
                </el-col>
                <el-col :span="8" class="head2">
                    <el-button type="text" @click="addScoreRange()">添加</el-button>
                    <el-button type="text" @click="defaultScoreRange()">恢复默认</el-button>
                </el-col>
            </el-row>
            <el-row class="my-row" v-for="(item, idx) in scoreRange">
                <el-col :span="4" >
                    <el-input size="mini" v-model="item.name"></el-input>
                </el-col>
                <el-col :span="3" style="text-align: center">
                    大于等于
                </el-col>
                <el-col :span="5" >
                    <el-input-number size="mini" :min="0" v-model="item.from"></el-input-number>
                </el-col>
                <el-col :span="2" style="text-align: center">
                    小于
                </el-col>
                <el-col :span="5" >
                    <el-input-number size="mini" :min="0" v-model="item.to"></el-input-number>
                </el-col>
                <el-col :span="3" >
                    <el-button type="text" @click="deleteScoreRange(idx)">删除</el-button>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="outLoading" @click="ouput">导 出</el-button>
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import Pagination from "@/components/pagination";
    import axios from "axios";
    import XLSX from "xlsx";

    export default {
        name: 'myExamDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null, tableData: null, outLoading: false,
                dialogVisible:false, loading: false, acadyearTermList: [], selections: [], stuDtls: [], stuSumy: [],
                searchForm:{ pageNo: 1, totalCount: 0, role: null, searchText: null, semester: null, hardName: null, hardClassId: null, courseId: null},
                scoreRange: [],
                scoreLevels: {score90: {name: '优秀', from: 90, to: 100}, score80: {name: '良好', from: 80, to: 90},
                    score70: {name: '中等', from: 70, to: 80},
                    score60: {name: '及格', from: 60, to: 70}, score0: {name: '不及格', from: 0, to: 60},},
            }
        },
        components:{ Pagination },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadAcadyearTermList();
	    },
        props: {
            myExam: {
                type: Object
            }
        },
        watch: {
            myExam: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        this.searchForm.searchText = null; this.searchForm.role = n.role;
                        this.searchForm.hardClassId = null; this.searchForm.courseId = null;
                        this.searchForm.hardName = n.hard_name;
                        this.searchForm.hardClassId = n.hard_class_id;
                        this.searchForm.courseId = n.course_id;
                        this.loadMyClassExams();
                        this.defaultScoreRange();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            handleSearch(){
                this.searchForm.pageNo = 1;
                this.loadMyClassExams();
            },
            addScoreRange(){
                this.scoreRange.push({name: "分数档"+this.scoreRange.length, from: 0, to: 0});
            },
            deleteScoreRange(idx){
                this.scoreRange.splice(idx, 1);
            },
            defaultScoreRange(){
                this.scoreRange = [{name: "优秀", from: 90, to: 100}, {name: "及格", from: 60, to: 100},
                    {name: "不及格", from: 0, to: 60}];
            },
            formatterExamName(row, column, cellValue, index) {
                if("exam_id" in row) return cellValue + "(" +row.exam_id + ")";
                return cellValue + "(" +row.id + ")";
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+2); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            loadMyClassExams(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), semester: mUtils.searchText(this.searchForm.semester),
                    courseId: this.searchForm.courseId, hardClassId: this.searchForm.hardClassId};
                let param = { controllerName: 'teacherMyClass', methodName: '/myClassExams', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0) {
                        this.tableData = data;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            selectionChange(val) {
                this.selections = val;
            },
            loadStudentData(){
                this.outLoading = true;
                let that = this; this.stuDtls = []; this.stuSumy = [];
                for (let i=0; i<this.selections.length; i++){
                    let exam = this.selections[i];
                    this.loadStudent(exam, function (){
                        console.log("callback", that.stuDtls.length, that.selections.length);
                        if(that.stuDtls.length == that.selections.length){
                            let rows = that.turn2Sumary(exam.score);
                            that.outputXlsxFile(rows);
                            that.outLoading = false;
                        }
                    });
                }
            },
            loadStudent(exam, callback){
                let that = this, examId = exam.id, examName = exam.name, fullScore = exam.score;
                if("exam_id" in exam) examId = exam.exam_id;
                axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
                axios({
                    method:'get', url:window.g.OSS_HOST+"answer/analyse/"+examId+"/exam_student_"+examId+".json?_=" + (new Date()).getTime(),
                }).then((res)=>{
                    let data = res.data, stuList = []; let hardName = this.searchForm.hardName;
                    let r1 = data[0], head = ['学号', '姓名', '班级', '分数', '客观题', '主观题'];
                    let summary={minScore: 1000, maxScore: 0, minName: null, maxName: null};
                    for (let i=0;i<r1.ti_list.length; i++) head.push('第'+ r1.ti_list[i].ix+'题');
                    let col_len = head.length, extStart = head.length, ext_col = {};
                    for (let i=0; i<data.length; i++){
                        let d = data[i];
                        if(d.hard_name == hardName){
                            let row = [d.student_no, d.student_name, d.hard_name], us=0, kg=0, zg=0, tiList = [], extList = [];
                            for (let j=0; j<d.ti_list.length; j++){
                                let ti = d.ti_list[j]; tiList.push(ti.us); us = us + ti.us;
                                if(ti.t2 == "Object") kg=kg+ti.us; else zg = zg+ti.us;
                                if("step_score" in ti){
                                    let step_score = ti.step_score;
                                    for (let k=0; k<step_score.length; k++){
                                        if(!(step_score[k].name in ext_col)){col_len ++; ext_col[step_score[k].name] = col_len;}
                                        let ssCol = ext_col[step_score[k].name];
                                        if(extList.length<=(ssCol-extStart)){
                                            for (let ei=0; ei<(col_len-extStart); ei++) extList.push("");
                                        }
                                        extList[ssCol-extStart-1] = step_score[k].score;
                                    }
                                }
                            }
                            if(extList.length > 0) tiList = [...tiList, ...extList];
                            row.push(us); row.push(kg); row.push(zg);
                            row = [...row, ...tiList];
                            stuList.push(row);
                        }
                    }
                    for(let ec in ext_col) head.push(ec);
                    stuList.unshift(head);
                    summary["count"] = stuList.length; summary["tScore"] = XEUtils.sum(stuList, item=>item[3]);
                    summary["avgScore"] = XEUtils.mean(stuList, item=>item[3]);
                    for (let i=0; i<stuList.length; i++){
                        let us = stuList[i][3];
                        for (let j=0; j<fullScore; j=j+10){
                            let fkey = "level"+j, endS = (j+10)==fullScore?(j+11):(j+10);
                            if(us >=j && us<endS){
                                if(fkey in summary) summary[fkey] = summary[fkey] + 1;
                                else summary[fkey] = 1;
                            }
                        }
                        for (let j=0; j<this.scoreRange.length; j++){
                            let sr = this.scoreRange[j], endS = sr.to==fullScore?(sr.to+1):sr.to;
                            if(us >= sr.from && us <endS){
                                if(sr.name in summary) summary[sr.name] = summary[sr.name] + 1;
                                else summary[sr.name] = 1;
                            }
                        }
                        if(summary.minScore > us){summary.minScore = us; summary.minName = stuList[i][1];}
                        if(summary.maxScore < us){summary.maxScore = us; summary.maxName = stuList[i][1];}
                    }
                    console.log(examName, stuList, summary);
                    that.stuDtls.push({name: examName, data: stuList});
                    that.stuSumy.push({name: examName, data: summary});
                    if(callback) callback();
                }).catch((error) => {
                    console.log("error", error);
                    this.loading = false;
                });
            },
            turn2Sumary(fullScore){
                console.log("turn2Sumary", this.stuSumy);
                let head = [''], rows = [], joins = ['实考人数'], totals = ['总分'], avgs = ['平均分'], mins = ['最低分'], minNames = ['最低分姓名'], maxs = ['最高分'], maxNames = ['最高分姓名'];
                for (let i=0; i<this.stuSumy.length; i++){
                    let d = this.stuSumy[i].data;
                    head.push(this.stuSumy[i].name); joins.push(d.count); totals.push(d.tScore); avgs.push(d.avgScore.toFixed(2));
                    mins.push(d.minScore); minNames.push(d.minName); maxs.push(d.maxScore); maxNames.push(d.maxName);
                }
                rows = [head, joins, totals, avgs];
                let sranges = [], lvs = [], levels= [];
                for (let j=0; j<this.scoreRange.length; j++){
                    let sr = this.scoreRange[j];
                    let rs = [sr.name], lv = [sr.name+'率'];
                    for (let i=0; i<this.stuSumy.length; i++){
                        let d = this.stuSumy[i].data;
                        if(sr.name in d) {
                            rs.push(d[sr.name]);  lv.push(parseFloat(d[sr.name]/d.count * 100).toFixed(2) + '%' );
                        }else {
                            rs.push(0); lv.push("0%");
                        }
                    }
                    sranges.push(rs); lvs.push(lv);
                }
                for (let j=0; j<fullScore; j=j+10){
                    let fkey = "level"+j, level = [j+'-'+(j+10)];
                    for (let i=0; i<this.stuSumy.length; i++) {
                        let d = this.stuSumy[i].data;
                        if(fkey in d)  level.push(d[fkey]); else level.push(0);
                    }
                    levels.push(level);
                }
                rows = [...rows, ...lvs, ...sranges, ...levels];
                rows.push(maxs);
                rows.push(maxNames);
                rows.push(mins);
                rows.push(minNames);
                return rows;
            },
            outputXlsxFile(sumRows) {
                console.log("sumRows", sumRows, this.stuDtls);
                let sheetNames = ['汇总'], sheetsList = {};
                sheetsList['汇总'] = XLSX.utils.aoa_to_sheet(sumRows);
                let wb = XLSX.utils.book_new();
                for (let i=0; i<this.stuDtls.length; i++){
                    let d = this.stuDtls[i], shtName = d.name;
                    // shtName = shtName.replace("Ⅱ", "2"); shtName = shtName.replace("（", "("); shtName = shtName.replace("）", ")");
                    shtName = shtName.substr(0, 13)+i;
                    sheetNames.push(shtName);
                    sheetsList[shtName] = XLSX.utils.aoa_to_sheet(d.data);
                }
                // sheetsList['成绩单']["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 },{ wpx: 100 } ,{ wpx: 100 },{ wpx: 100 }];
                console.log("SheetNames", sheetNames, sheetsList);
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;
                XLSX.writeFile(wb, this.searchForm.hardName + "_成绩报告.xlsx");
            },
            ouput(){
                console.log("selections", this.selections);
                if(this.selections.length ==0){
                    this.$alert('请勾选试卷!！', '提示'); return ;
                }
                let score = 0;
                for (let i=0; i<this.selections.length; i++){
                    if(score > 0 && score != this.selections[i].score){
                        this.$alert('请选择相同分数的试卷!！', '提示'); return ;
                    }else{
                        score = this.selections[i].score;
                    }
                }
                this.loadStudentData();
            }

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .myExamDialog{
        .head1{
            font-weight: bold;
            font-size: 18px;
        }
        .head2{
            line-height: 40px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 16px;
        }
        .head3{
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 16px;
        }
        .head-warning {
            margin-top: 20px;
            color: red;
        }
    }


</style>


