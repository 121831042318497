<template>
    <div class="myClassDialog">
        <el-dialog :title="myTitle" width="70%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称或入学年"  @keyup.enter.native='handleSearch'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='handleSearch'>查询</el-button>
                </el-form-item>
            </el-form>

            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>

                <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                <el-table-column prop="school_name" label="所属单位" align='center' ></el-table-column>
                <el-table-column prop="profession_name" label="专业" align='center' ></el-table-column>
                <el-table-column prop="student_count" label="学生数量" align='center' ></el-table-column>
                <el-table-column prop="teacher_name" label="班主任" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='{row}'>
                        <el-button type="primary" v-if="row.class_teacher_id == null" icon='edit' size="mini" @click='addToMyClass(row)'>添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-if="searchForm.totalCount>searchForm.pageSize" :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount"
                        @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import Pagination from "@/components/pagination";

    export default {
        name: 'myClassDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false, tableData: null,
                searchForm:{ pageNo: 1, pageSize: 20, totalCount: 0, searchText: null, },
                myClassForm:{ id: null, licenceId: null,  name: null, year: null, schoolId: null, professionId: null,remark: null,  },
                myClassRules: {
                    name: [{ required: true, message: '请输入行政班名称', trigger: 'blur' }],
                    year: [{ required: true, message: '请输入入学年份', trigger: 'blur' }],
                    schoolId: [{ required: true, message: '请选择单位', trigger: 'blur' }],
                    professionId: [{ required: true, message: '请选择专业', trigger: 'blur' }],
                },
            }
        },
        components:{ Pagination },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            myClass: {
                type: Object
            }
        },
        watch: {
            myClass: {
                deep: true, immediate:true,
                handler(n, o){
                    this.searchForm.searchText = null;
                    this.loadHardClassList();
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            handleSearch(){
                this.searchForm.pageNo = 1;
                this.loadHardClassList();
            },
            loadHardClassList(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadHardClassList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadHardClassList()
            },

            addToMyClass(row){
                let that = this;
                this.$confirm('是否设置此班级的班主任是我?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'sclHardClass', methodName: '/saveOrUpdate', param: {id: row.id, classTeacherId: this.userInfo.id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '设置成功!'});
                            that.dialogVisible = false;
                            that.$emit('ok');
                        }
                        if (code == -3) {
                            that.$message({ type: 'error', message: '设置失败'});
                            return ;
                        }
                    });
                }).catch(() => {

                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .myClassDialog{

    }


</style>


