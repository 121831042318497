<template>
    <div class="myClassCourseDialog">
        <el-dialog :title="myTitle" width="50%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="myClassCourseForm" ref="myClassCourseDialogForm" :rules="myClassCourseRules" label-suffix=":">
                <el-form-item label="班级"  prop="courseId" :label-width="formLabelWidth">
                    <el-select v-model="myClassCourseForm.hardClassId" filterable placeholder="请选择" remote :remote-method="remoteHardClass" style="width: 100%">
                        <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属课程"  prop="courseId" :label-width="formLabelWidth">
                    <el-select v-model="myClassCourseForm.courseId" filterable placeholder="请选择" remote :remote-method="remoteCourse" style="width: 100%">
                        <el-option v-for="item in courseList" :key="item.id" :label="item.name+'('+item.course_no+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'myClassCourseDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                courseList: [], courseForm: {id: null, searchText: null},
                hardClassList: [], hardClassForm: {id: null, searchText: null},
                myClassCourseForm:{ id: null, teacherId: null, hardClassId: null, courseId: null,  },
                myClassCourseRules: {
                    hardClassId: [{ required: true, message: '请选择班级', trigger: 'blur' }],
                    courseId: [{ required: true, message: '请选择课程', trigger: 'blur' }],
                    
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadCourseList();
            this.loadHardClassList();
	    },
        props: {
            myClassCourse: {
                type: Object
            }
        },
        watch: {
            myClassCourse: {
                deep: true, immediate:true,
                handler(n, o){
                    this.hardClassForm.id = null; this.hardClassForm.searchText = null;
                    this.courseForm.id = null; this.courseForm.searchText = null;
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            remoteCourse(query){
                if(query !== ''){
                    this.courseForm.id = null;
                    this.courseForm.searchText = query;
                    this.courseList = [];
                    this.loadCourseList();
                }
            },
            loadCourseList(){
                let search = { licenceId: this.userInfo.licenceId, //schoolId: this.userInfo.schoolId,
                    id: mUtils.searchText(this.courseForm.id),  searchText: mUtils.searchText(this.courseForm.searchText)};
                let param = { controllerName: 'sclCource', methodName: '/list', pageNo: 0, pageSize: 50, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (data.code == 0) {
                        this.courseList = data.result;
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            remoteHardClass(query){
                if(query !== ''){
                    this.hardClassForm.id = null;
                    this.hardClassForm.searchText = query;
                    this.hardClassList = [];
                    this.loadHardClassList();
                }
            },
            loadHardClassList(){
                let search = { id: mUtils.searchText(this.hardClassForm.id),  searchText: mUtils.searchText(this.hardClassForm.searchText), licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: 1, pageSize: 50, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.hardClassList = data.result;
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            submitForm(){
                console.log(this.myClassCourseForm);
                let that = this;
                this.$refs.myClassCourseDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.myClassCourseForm.teacherId = this.userInfo.id;
                        let param = { controllerName: 'teacherMyClass/', methodName: 'addHardCourse', param: this.myClassCourseForm};
                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('myClassCourse', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else{
                                that.$message({ type: 'error', message: '您已是授课老师'});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .myClassCourseDialog{

    }


</style>


